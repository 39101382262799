* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

html,
body {
    height: 100%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

.login-container {
    background-color: #66B9E3;
    background-image: url("../../assets/Images/main-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-position: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.main-card {
    z-index: 1;

}

.login-form-label label {
    font-size: 18px;
    // font-weight: 600;
}

.login-form-label input {
    font-size: 18px;
}



.login-form-label span {
    background-color: #000;

    color: white;
}

.center-card {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 20px;

    &>img {
        // height: 200px;
        width: 100%;
        object-fit: contain;
        padding: 10px 0;
        filter: drop-shadow(4px 0px 5px #000000);
    }

    &>h1 {
        color: #009EE2;
        font-size: 48px;
        text-align: center;
        margin: 10px 0;
    }
}

.form-card {
    background-color: #fff;
    width: 400px;
    height: 350px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
    z-index: 2 !important;

    .forgot {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        a {
            color: red;
            // text-decoration: none;
            font-weight: 600;
        }
    }

    .login_title {
        text-align: center;
        font-weight: 700;
        color: black;
    }

    .remember-me {
        display: inline-flex;
        margin: 0;
        color: #000;
    }

    .register {
        margin-top: 20px;
        text-align: center;
        color: #000;

        a {
            color: red;
            font-weight: 600;
        }
    }
}


$color_1: black;
$font-family_1: "Roboto", sans-serif;
$font-family_2: "Rock Salt", cursive;

figure {
    &:has(figcaption) {
        background-blend-mode: color-burn;
        height: 200px;
        width: 200px;
        object-fit: cover;
        background: #e8ebea;
        display: inline-block;
        margin: 55px 75px 36px;
        padding: 15px 15px 72px;
        text-align: center;
        text-decoration: none;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
        transition: all 0.2s linear;
        position: relative;
        z-index: 1;
    }
}

figcaption {
    color: $color_1;
    font-family: $font-family_1;
    font-family: $font-family_2;
}

@keyframes moveToPosition {
    from {
        opacity: 0; // Start invisible
        transform: translate(-50%, -50%) scale(0.8); // Start from center with zoom-out
    }

    to {
        opacity: 1; // Fully visible
        transform: translate(0, 0) rotate(var(--rotate, 0deg)) scale(1); // Move to final position and rotate
    }
}

.memory-pictures {
    height: 100%;
    width: 100%;
    z-index: 0;
    position: absolute;
    overflow: hidden;

    .memory-item {
        position: absolute;
        // filter: grayscale(1);
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
        left: 50%;
        top: 50%;
        animation: moveToPosition 1s ease-out forwards;
        scale: 1;
        opacity: 0.8;

        &:hover {
            filter: none;
            opacity: 1;
            scale: 1.1;
        }

        .handwriting {
            transform: rotate(5deg);
            margin-top: 1rem;
            margin-bottom: -1rem;
        }

        @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
                animation-delay: #{$i * 0.2}s;
            }
        }
    }


    // Final positions and rotations for each item
    .memory-item:nth-child(1) {
        background-color: rgb(252, 193, 83);
        --rotate: -5deg;
        left: 0; // Move to top-left
        top: 0;
    }

    .memory-item:nth-child(2) {
        background-color: #3DC5C4;
        --rotate: -14deg;
        left: 15%;
        top: 0;
    }

    .memory-item:nth-child(3) {
        background-color: #B7DD71;
        --rotate: 30deg;
        left: 30%;
        top: 0;
    }

    .memory-item:nth-child(4) {
        background-color: #F0E08A;
        --rotate: 0deg;
        left: 45%;
        top: 0;
    }

    .memory-item:nth-child(5) {
        background-color: #F19134;
        --rotate: -14deg;
        left: 62%;
        top: 0;
    }

    .memory-item:nth-child(6) {
        background-color: #1FA0D7;
        --rotate: 14deg;
        left: 80%;
        top: 0;
    }
}

.memory-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.memory-item {
    position: absolute;

}

.login-btn {
    margin-top: 22px;
    padding: 6px;
}

@media only screen and (max-width: 1440px) {
    figure {
        &:has(figcaption) {
            height: 170px;
            width: 170px;
        }
    }

    .memory-pictures {
        .memory-item:nth-child(1) {
            --rotate: -5deg;
            left: 0; // Move to top-left
            top: 0;
        }

        .memory-item:nth-child(2) {
            --rotate: -14deg;
            left: 20%;
            top: 0;
        }

        .memory-item:nth-child(3) {
            --rotate: 30deg;
            left: 40%;
            top: 0;
        }

        .memory-item:nth-child(4) {
            --rotate: 0deg;
            left: 60%;
            top: 0;
        }

        .memory-item:nth-child(5) {
            --rotate: -14deg;
            left: 80%;
            top: 0;
        }

        .memory-item:nth-child(6) {
            display: none;
        }
    }

}

@media only screen and (max-width: 1024px) {
    .memory-pictures {
        .memory-item:nth-child(1) {
            --rotate: -5deg;
            left: 0; // Move to top-left
            top: 0;
        }

        .memory-item:nth-child(2) {
            --rotate: -14deg;
            left: 22%;
            top: 0;
        }

        .memory-item:nth-child(3) {
            --rotate: 30deg;
            left: 47%;
            top: 0;
        }

        .memory-item:nth-child(4) {
            --rotate: 0deg;
            left: 70%;
            top: 0;
        }

        .memory-item:nth-child(5) {
            display: none;
        }
    }

}

@media only screen and (max-width: 768px) {
    .memory-pictures {
        display: none;
    }
}

@media (max-width: 425px) {
    .center-card {
        width: 350px;

        .form-card {
            width: 350px;
        }
    }
}

@media (max-width: 375px) {
    .center-card {
        width: 300px;

        img {
            width: 80%;
        }

        .form-card {
            width: 300px;
        }
    }
}